/**
 * A constant to track whether custom events are supported.
 *
 * @private
 * @type {boolean}
 */
const supportsCustomEvent = typeof window.CustomEvent === 'function';

/**
 * A wrapper around CustomEvent (for modern browers) and document.createEvent
 * (for older browsers). Can be removed in favor of CustomEvent when we drop
 * support for IE11.
 *
 * @template T
 * @param {string} type The type of event to create.
 * @param {CustomEventInit<T>} init The params to pass to the event.
 * @returns {CustomEvent<T>} The created event.
 */
export function createEvent(type, init) {
  if (supportsCustomEvent) {
    return new window.CustomEvent(type, init);
  }

  const event = document.createEvent('Event');

  if (init) {
    event.initEvent(type, init.bubbles, init.cancelable);
    event.detail = init.detail;
  } else {
    event.initEvent(type, false, false);
  }

  return event;
}
