import { ensure, getBootstrapData, getUrlParameter } from '$shared/utils.js';
import FullSignup from './fullsignup.js';
import BannerSignup from './bannersignup.js';
import InlineSignup from './inlinesignup.js';
import ToastSignup from './toastsignup.js';

/**
 * The element containers for services that have already been rendered.
 *
 * @type {WeakSet<HTMLElement>}
 */
const populatedElements = new WeakSet();

export default class EmailSignupService {
  constructor(options) {
    ensure('EmailSignup', options, ['el']);
    /** @type {HTMLElement} */
    const el = options.el;

    // if this element has already been populated, don't do it again
    if (populatedElements.has(el)) {
      return;
    }

    populatedElements.add(el);

    /** @type {HTMLElement} */
    this.el = el;
    this.dataset = this.el.dataset;

    const viewType = this.dataset.signupStyle;
    const viewOptions = { el: this.el };

    viewOptions.placement = this.dataset.placement;
    viewOptions.signupRef = this.getParameter('signup-ref');
    viewOptions.donateCampaign = this.getParameter('donate-campaign');
    viewOptions.newsletters = this.getNewsletterData(viewType);
    viewOptions.tagline = this.dataset.tagline;
    viewOptions.customCSS = this.dataset.customCss;
    viewOptions.initialEmail = getUrlParameter('email').replace(' ', '+');
    viewOptions.successMessage = getUrlParameter('success_message');
    this.view = this.constructView(viewType, viewOptions);
  }

  render() {
    if (this.view) {
      this.view.render();
    }
  }

  getParameter(name) {
    return getUrlParameter(name) || this.el.getAttribute(`data-${name}`);
  }

  getNewsletterData(viewType) {
    let newsletterBase = getBootstrapData('newsletters');

    if (this.el.dataset.newsletterList) {
      try {
        newsletterBase = JSON.parse(this.el.dataset.newsletterList);
      } catch (e) {
        console.warn(`Encountered error parsing newsletter list: ${e.message}`);
      }
    }

    // Initially, the newsletter-override attribute would specify which
    // newsletters to display and would default all of them to checked. With
    // custom Newsletter Signup Pages, we can define which newsletters to display and
    // check only a subset of them. We give precedence to the
    // newsletter-override URL param to allow legacy URLs with that param to
    // function as they did previously.
    const newsletterList =
      this.getParameter('newsletter-override') ||
      this.getParameter('displayed-newsletters');
    const checkedList =
      this.getParameter('newsletter-override') ||
      this.getParameter('checked-newsletters');

    if (newsletterList) {
      const newsletterIds = newsletterList.split(',');
      newsletterBase = newsletterBase
        .filter((x) => newsletterIds.includes(x.id))
        .sort((a, b) => {
          return newsletterIds.indexOf(a.id) - newsletterIds.indexOf(b.id);
        });
    } else if (viewType === 'inline') {
      newsletterBase = newsletterBase.filter((x) => x.inline_signup);
    } else {
      newsletterBase = newsletterBase.filter((x) => !x.project_specific);
    }

    const newsletterData = this.adjustDefaultChecked(
      newsletterBase,
      checkedList
    );

    return newsletterData;
  }

  adjustDefaultChecked(newsletterData, checkedNewsletters) {
    const defaultCheckedOverride = this.getParameter('newsletters') || '';

    // If specific newsletters are identified, they should be default checked
    const overrideNewsletters = checkedNewsletters || '';

    if (defaultCheckedOverride || overrideNewsletters) {
      const newsletterIds = defaultCheckedOverride
        .split(',')
        .concat(overrideNewsletters.split(','));
      newsletterData.forEach((newsletter) => {
        if (newsletterIds.includes(newsletter.id)) {
          newsletter.default_subscribe = true;
        } else {
          newsletter.default_subscribe = false;
        }
      });
    }
    return newsletterData;
  }

  constructView(viewType, viewOptions) {
    if (viewType === 'full') {
      return new FullSignup(viewOptions);
    } else if (viewType === 'banner') {
      return new BannerSignup(viewOptions);
    } else if (viewType === 'inline') {
      return new InlineSignup(viewOptions);
    } else if (viewType === 'toast') {
      return new ToastSignup(viewOptions);
    }

    console.error(`Unrecognized view type: ${viewType}.`);
  }
}
