import BannerSignup from './bannersignup.js';

export default class ToastSignup extends BannerSignup {
  getClassName() {
    return 'ToastSignup';
  }

  templateData() {
    const data = super.templateData();
    data.button_text =
      'Subscribe<span class="toast-subscribe-extra-text"> to our newsletters</span>';
    data.banner_tagline = 'We drill down on one topic in this week’s news.';
    return data;
  }

  // Disable the scrolling behavior on success
  maybeScrollToTop() {}
}
