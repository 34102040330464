// local
import { createEvent } from '../util/events.js';
import http from '$shared/http.js';
import { ensure } from '$shared/utils.js';
import {
  trackEvent,
  trackParselyNewsletterConversion,
} from '../analytics/helpers.js';

export default class BaseSignup {
  constructor(options) {
    ensure(this.constructor.name, options, ['el']);
    /** @type {HTMLElement} */
    this.el = options.el;
    this.options = options;
  }

  render() {
    this.el.innerHTML = this.template()(this.templateData());
    this.initializeListeners();
  }

  templateData() {
    return {
      newsletters: this.options.newsletters,
      email: this.options.initialEmail,
      tagline: this.options.tagline,
      donateCampaign: this.options.donateCampaign,
      custom_css: this.options.customCSS,
      successMessage: this.options.successMessage,
    };
  }

  initializeListeners() {
    this.el.addEventListener('click', (event) => {
      if (event.target.matches('.subscribe-button-js')) {
        this.subscribe();
      }
    });

    this.el.addEventListener('keydown', (event) => {
      const target = event.target;

      if (target.matches('.subscribe-button')) {
        this.handleEnterPressed(event);
      }

      if (target.matches('.subscribe-email-input')) {
        this.handleEnterPressed(event);
      }

      if (target.matches('.newsletter-option-item')) {
        this.handleSpacePressed(event);
      }
    });
  }

  handleEnterPressed(event) {
    if (event.keyCode === 13) {
      // Enter
      this.subscribe();
      event.preventDefault();
    }
  }

  handleSpacePressed(event) {
    if (event.keyCode === 32) {
      // Spacebar
      const input = event.target.querySelector('input');
      input.checked = !input.checked;
      event.preventDefault();
    }
  }

  subscribe() {
    const data = this.getData();
    this.updateValidation(this.validate(data));

    if (this.valid) {
      this.sendData(data);
    }
  }

  getData() {
    return this.serializeForm();
  }

  serializeForm() {
    const values = { newsletters: [] };
    const inputs = this.el.querySelectorAll('input');

    inputs.forEach((input) => {
      if (input.type === 'checkbox') {
        if (input.checked) {
          values.newsletters.push(input.value);
        }
      } else {
        values[input.name] = input.value;
      }
    });

    return values;
  }

  updateValidation(validation) {
    this.valid = validation.valid;
    if (this.valid) {
      this.clearErrors();
    } else {
      this.displayErrors(validation.errors);
    }
  }

  validate(data) {
    let valid = true;
    const errors = [];
    const htmlValidation = this.getEmailElement().validationMessage;

    data.email = data.email.trim();

    if (htmlValidation) {
      valid = false;
      errors.push(htmlValidation);
    } else if (!data.newsletters || data.newsletters.length === 0) {
      valid = false;
      errors.push('At least one newsletter must be selected.');
    } else if (!data.email) {
      valid = false;
      errors.push('Email cannot be blank.');
    } else if (data.email.search(/\s/) !== -1) {
      valid = false;
      errors.push('Email addresses cannot contain spaces.');
    } else if (data.email.indexOf('@') === -1) {
      valid = false;
      errors.push('Email addresses must contain an "@".');
    }

    if (valid) {
      return { valid };
    } else {
      return { valid, errors };
    }
  }

  clearErrors() {
    this.el.classList.remove('error');
    this.el.querySelector('.subscribe-error-container').innerHTML = '';
  }

  displayErrors(errors) {
    this.el.classList.add('error');
    this.el.querySelector('.subscribe-error-container').innerHTML =
      errors.join(' ');
  }

  // This should be overridden so that an unminified identifer of the class can
  // be saved with each signup.
  getClassName() {
    return this.constructor.name;
  }

  sendData(data) {
    this.setWorking(true);

    // Leaving this here for aid in testing without submitting to the server.
    // Uncomment this block to test locally.
    // window.setTimeout(() => {
    //   this.handleSuccess({});
    //   this.setWorking(false);
    // }, 1500);
    // return;

    const payload = {
      signup: Object.assign({}, data, {
        placement: this.options.placement,
        signup_ref: this.options.signupRef,
        style: this.getClassName(),
        url: window.location.href,
        referer: document.referrer,
        t: window.readCookie('t'),
        _utpv: window.readCookie('_utpv'),
        user_agent: window.navigator.userAgent,
      }),
    };

    http
      .post('/api/v3/email/subscribe', {
        json: payload,
      })
      .json()
      .then((data) => {
        this.handleSuccess(data);
      })
      .catch(async (error) => {
        this.handleFailure(await error.response.json());
      })
      .finally(() => {
        this.setWorking(false);
      });
  }

  handleSuccess(data) {
    this.trackSuccess();

    if (data.status === 'redirect' && data.redirect_url) {
      window.location.assign(data.redirect_url);
    }

    window.dispatchEvent(createEvent('tmp_email_signup_success'));

    this.renderSuccess();
  }

  handleFailure(errorData) {
    const message =
      errorData.error?.message ||
      'Something went wrong saving your subscription. Please try again.';
    this.updateValidation({
      valid: false,
      errors: [message],
    });
  }

  trackSuccess() {
    // See https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=8256709344012883295-NA&client_type=gtag#sign_up
    trackEvent('sign_up', {
      method: `${this.options.placement}|${this.getClassName()}`,
      placement: this.options.placement,
      style: this.getClassName(),
      newsletter_sign_up: true,
    });
    trackParselyNewsletterConversion(this.options.placement);
  }

  maybeScrollToTop() {
    if (window.innerWidth < 768) {
      window.scrollTo({
        top: this.el.offsetTop - 128 /** header padding */,
        behavior: 'smooth',
      });
    }
  }

  renderSuccess() {}

  setWorking(workingState) {
    this.working = workingState;
    const subscribeButton = this.el.querySelector('.subscribe-button-js');

    if (subscribeButton) {
      if (workingState) {
        subscribeButton.textContent = 'Saving...';
      } else {
        subscribeButton.textContent = 'Subscribe';
      }
    }
  }

  getEmailElement() {
    return this.el.querySelector('[name="email"]');
  }
}
