import BaseSignup from './basesignup';
import template from './fullsignup.mustache';

export default class FullSignup extends BaseSignup {
  template() {
    return template;
  }

  render() {
    super.render();
    if (this.options.successMessage) {
      this.renderSuccess(0);
    }
    if (this.customSuccessHTML) {
      this.renderCustomSuccessHTML();
    }
  }

  getClassName() {
    return 'FullSignup';
  }

  get successContainer() {
    return this.el.querySelector('.full-signup-success-container');
  }

  get customSuccessHTML() {
    return document.querySelector('#email-signup-success-html')?.content;
  }

  renderCustomSuccessHTML() {
    this.successContainer.replaceChildren(
      this.customSuccessHTML.cloneNode(true)
    );
  }

  async renderSuccess(duration = 200) {
    const formContainer = this.el.querySelector('.full-signup-form-container');

    formContainer.style.opacity = 0;
    await formContainer.animate({ opacity: [1, 0] }, duration).finished;
    formContainer.style.display = 'none';

    this.successContainer.style.display = 'block';
    this.successContainer.style.opacity = 1;
    await this.successContainer.animate({ opacity: [0, 1] }, duration).finished;

    if (window.innerWidth < 768) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
