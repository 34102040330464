// We set up the ga object here so that any code depending on the presence of
// ga can import this file and forever assume that they can use it normally,
// without checking whether its really running all the time. This decouples the
// decision of "Do we want analytics right now?" from everywhere we actually
// use analytics.
window.dataLayer = window.dataLayer || [];
export const gtag =
  window.gtag ||
  function () {
    window.dataLayer.push(arguments);
  };

export const ga = (window.ga =
  window.ga ||
  function () {
    (ga.q = ga.q || []).push(arguments);
  });
