// packages
import ky from 'ky';

/**
 * Returns the CSRF token from the meta tag. This is injected by Rails using
 * the `csrf_meta_tags` helper.
 *
 * @returns {string}
 */
export function getCsrfToken() {
  return document
    .querySelector("meta[name='csrf-token']")
    ?.getAttribute('content');
}

/**
 * Audits whether a given URL is on the same origin as the current page. We do
 * not want to send the CSRF token to different origins because sometimes third-party
 * services will throw errors if they receive a header they don't expect.
 *
 * @private
 * @param {string} url
 * @returns {boolean}
 */
function isUrlSameOrigin(url) {
  // it is a relative URL so same origin
  if (!url.startsWith('http')) {
    return true;
  }

  try {
    // if the hostname matches, it's the same origin
    return new URL(url).hostname === window.location.hostname;
  } catch (_) {
    // if something goes wrong with the URL parsing we err on the side of
    // caution and assume it's the same origin
    return true;
  }
}

/**
 * @param {Request} request
 */
function applyCsrfTokenToRequest(request) {
  // only need the CSRF token for non-GET requests
  if (request.method !== 'GET') {
    // send CSRF token only with same origin requests
    if (isUrlSameOrigin(request.url)) {
      request.headers.set('X-CSRF-Token', getCsrfToken());
    }
  }
}

export default ky.create({
  hooks: { beforeRequest: [applyCsrfTokenToRequest] },
});
