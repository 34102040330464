// packages
import delay from 'underscore/modules/delay.js';

// local
import { gtag } from './ga.js';

/**
 * Track a user interaction in GA.
 */
export function trackEvent(eventName, eventData = {}) {
  // Convert legacy event format to GA4 format
  if (typeof eventData === 'string') {
    eventData = convertLegacyEvent(...arguments);
    eventName = eventData.event_name;
  }

  try {
    gtag('event', eventName, eventData);
  } catch (e) {
    console.warn('error reporting event', eventData);
  }
}

/**
 * Convert a Universal analytics style event into GA4 format.
 */
function convertLegacyEvent(
  category,
  action,
  label = '',
  value = undefined,
  options = {}
) {
  return {
    event_name: `${category}_${action}`,
    event_category: category,
    event_action: action,
    event_label: label,
    event_value: value,
  };
}

/**
 * Track a non-interactive event in GA.
 */
export function trackPassiveEvent(
  category,
  action,
  label = '',
  value = undefined
) {
  const passiveOptions = { nonInteraction: 1 };
  return trackEvent(category, action, label, value, passiveOptions);
}

export const trackParselyConversion = (function () {
  // make a queue for conversion events
  const parselyConversionEventQueue = [];

  // wrapper function safely handles either case
  const capture = (type, label) => {
    if (window.PARSELY && window.PARSELY.loaded) {
      report(type, label);
    } else {
      parselyConversionEventQueue.push([type, label]);
    }
  };

  const report = function (type, label) {
    try {
      window.PARSELY.conversions[type](label);
      // console.log('reported', type, label)
    } catch (e) {
      console.log(
        `Error reporting Parsely conversion. type: ${type}, label: ${label}, error: ${e}`
      );
    }
  };

  // Preload an event if it's present in the bootstrap
  if (window.TMPBootstrapData && window.TMPBootstrapData.parselyConversion) {
    const conversion = window.TMPBootstrapData.parselyConversion;
    capture(conversion.type, conversion.label);
  }

  // flush the queue when the tracker loads
  window.PARSELY = window.PARSELY || {
    onload: function () {
      delay(() => {
        parselyConversionEventQueue.forEach((event) => {
          report(event[0], event[1]);
        });
      });
    },
  };

  return capture;
})();

export function trackParselyNewsletterConversion(label) {
  trackParselyConversion('trackNewsletterSignup', label);
}

export function trackParselyMonthlyDonationConversion(label) {
  trackParselyConversion('trackSubscription', label);
}

export function trackParselyDonationConversion(label) {
  trackParselyConversion('trackPurchase', label);
}

export function trackParselyCustomConversion(label) {
  trackParselyConversion('trackCustom', label);
}

// Make this available site-wide for graphics
window.TMPAnalytics = window.TMPAnalytics || {};
window.TMPAnalytics.trackConversion = trackParselyCustomConversion;
window.TMPAnalytics.trackEvent = trackEvent;
// For backwards compatibility with old graphics.
window.report = trackEvent;
