import BaseSignup from './basesignup';
import template from './bannersignup.mustache';

const ANIMATION_DURATION = 200;

export default class BannerSignup extends BaseSignup {
  template() {
    return template;
  }

  getClassName() {
    return 'BannerSignup';
  }

  renderSuccess() {
    const successContainer = this.el.querySelector(
      '.banner-signup-success-container'
    );
    const formContainer = this.el.querySelector(
      '.banner-signup-form-container'
    );
    const signupEl = this.el.querySelector('.banner-signup');
    const button = this.el.querySelector('.subscribe-button');

    const a = document.createElement('a');
    a.textContent = 'donate';
    a.classList.add('subscribe-button');
    a.href = `/donate?via=${this.options.donateCampaign}`;

    button.replaceWith(a);

    const collapsedHeight = successContainer.offsetHeight;
    const currentHeight = signupEl.getBoundingClientRect().height;

    this.el.classList.add('success-state');
    signupEl.style.height = `${currentHeight}px`;
    formContainer.style.position = 'absolute';

    signupEl.addEventListener(
      'transitionend',
      async () => {
        await signupEl.animate(
          { height: [`${currentHeight}px`, `${collapsedHeight}px`] },
          ANIMATION_DURATION
        ).finished;

        signupEl.style.height = 'auto';

        this.maybeScrollToTop();
      },
      { once: true }
    );
  }

  initializeListeners() {
    super.initializeListeners();

    this.el.addEventListener('click', (event) => {
      if (event.target.matches('.subscribe-intro-button-js')) {
        this.expand();
      }
    });

    this.el.addEventListener('keydown', (event) => {
      if (event.target.matches('.subscribe-intro-button-js')) {
        this.handleIntroEnterPressed();
      }
    });
  }

  handleIntroEnterPressed(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.expand();
    }
  }

  async expand() {
    const formContainer = this.el.querySelector(
      '.banner-signup-form-container'
    );
    const displayContainer = this.el.querySelector(
      '.banner-signup-display-container'
    );
    const signupEl = this.el.querySelector('.banner-signup');

    const button = this.el.querySelector('.subscribe-button');
    button.classList.remove('subscribe-intro-button-js');
    button.classList.add('subscribe-button-js');

    const expandedHeight = formContainer.offsetHeight;
    const currentHeight = signupEl.getBoundingClientRect().height;

    displayContainer.style.opacity = 0;
    await displayContainer.animate({ opacity: [1, 0] }, ANIMATION_DURATION)
      .finished;
    this.el.classList.add('expanded');

    await signupEl.animate(
      { height: [`${currentHeight}px`, `${expandedHeight}px`] },
      ANIMATION_DURATION
    ).finished;
    formContainer.style.position = 'static';
    signupEl.style.height = 'auto';
  }
}
