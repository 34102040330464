import BaseSignup from './basesignup';
import template from './inlinesignup.mustache';

export default class InlineSignup extends BaseSignup {
  constructor(options) {
    super(options);
    if (this.options.newsletters.length <= 1) {
      this.state = 'no-options';
    } else {
      this.state = 'display';
    }
  }

  templateData() {
    const data = super.templateData();
    data.display_options = this.state !== 'no-options';
    data.button_text = 'Sign Up';
    return data;
  }

  template() {
    return template;
  }

  getClassName() {
    return 'InlineSignup';
  }

  initializeListeners() {
    super.initializeListeners();

    this.el.addEventListener('focus', (event) => {
      if (event.target.matches('.subscribe-email-input')) {
        this.showOptions();
      }
    });

    this.el.addEventListener('click', () => {
      this.showOptions();
    });
  }

  async showOptions() {
    if (this.state === 'display') {
      this.state = 'options';
      const options = this.el.querySelector('.newsletter-options-list');
      this.el.classList.add('expanded');
      const optionsHeight = options.getBoundingClientRect().height;

      await options.animate({ height: ['0', `${optionsHeight}px`] }, 200)
        .finished;
      options.style.height = 'auto';
      options.style.opacity = 1;

      await options.animate({ opacity: [0, 1] }, 200).finished;

      const emailInput = this.el.querySelector('.subscribe-email-input');
      emailInput.focus();
      emailInput.style.pointerEvents = 'all';
    }
  }

  async renderSuccess() {
    const container = this.el.querySelector('.inline-signup');
    const formContainer = this.el.querySelector(
      '.inline-signup-form-container'
    );
    const successContainer = this.el.querySelector(
      '.inline-signup-success-container'
    );
    const currentHeight = container.offsetHeight;
    const heightAdjustment = currentHeight - formContainer.offsetHeight;
    container.style.height = `${currentHeight}px`;

    // hide the form container
    formContainer.style.opacity = 0;
    await formContainer.animate({ opacity: [1, 0] }, 200).finished;
    formContainer.style.display = 'none';

    // then unhide the success container
    successContainer.style.display = 'block';
    successContainer.style.opacity = 1;

    const newHeight = successContainer.offsetHeight;

    await Promise.all([
      successContainer.animate({ opacity: [0, 1] }, 200).finished,
      container.animate(
        { height: [`${currentHeight}px`, `${newHeight + heightAdjustment}px`] },
        200
      ).finished,
    ]);

    container.style.height = 'auto';

    if (window.innerWidth < 768) {
      window.scrollTo({
        top: this.el.getBoundingClientRect().top,
        behavior: 'smooth',
      });
    }
  }
}
